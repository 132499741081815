export default {
  headers: [
    {
      id: 'mainHeader',
      logo: {
        alt: 'florida logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/grgur/logo.png',
        url: 'https://www.floridacoastalteam.com/',
      },
      breakpoint: 1680,
      desktopMenu: {
        type: 'default',
        disableOnHoverMenuExpand: false,
        logo: {
          show: true,
          reverseOrder: false,
          containerClassName: 'on-grgur-desktop-header-logo-container',
          imageClassName: 'on-grgur-desktop-header-logo',
        },
        headerClassName: 'on-grgur-desktop-header',
        headerSectionsClassName: 'on-grgur-desktop-header-sections',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '' || null,
        itemClassName: 'on-grgur-desktop-item',
        menuClassName: 'on-grgur-desktop-menu',
        menuLabelActiveClassName: '',
        menuItemClassName: 'on-grgur-desktop-item-folder',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'trade-in-item',
            content: 'TRADE IN',
            url: 'https://trade-in.floridacoastalteam.com/',
          },
          {
            id: 'cash-offer-item',
            content: 'CASH OFFER',
            url: 'https://cashoffer.floridacoastalteam.com/',
          },
          {
            id: 'buy-item',
            content: 'BUY',
            url: 'https://search.floridacoastalteam.com/',
          },
          {
            id: 'sell-item',
            content: 'SELL',
            menuItems: [
              {
                id: 'sell-item-home-val',
                url: 'https://www.floridacoastalteam.com/home-valuation',
                content: 'HOME VALUATION',
              },
              {
                id: 'sell-item-why-with-us',
                url: 'https://www.floridacoastalteam.com/why-list-with-us',
                content: 'WHY LIST WITH US',
              },
            ],
          },
          {
            id: 'communities-item',
            content: 'COMMUNITIES',
            menuItems: [
              {
                id: 'communities-item-all',
                url: 'https://www.floridacoastalteam.com/communities',
                content: 'ALL COMMUNITIES',
              },
              {
                id: 'communities-item-augustine',
                url: 'https://www.floridacoastalteam.com/communities/st--augustine-real-estate',
                content: 'ST. AUGUSTINE',
              },
              {
                id: 'communities-item-johns',
                url: 'https://www.floridacoastalteam.com/communities/st-johns-real-estate',
                content: 'ST JOHNS',
              },
              {
                id: 'communities-item-vedra-beach',
                url: 'https://www.floridacoastalteam.com/communities/ponte-vedra-beach-real-estate',
                content: 'PONTE VEDRA BEACH',
              },
              {
                id: 'communities-item-vedra',
                url: 'https://www.floridacoastalteam.com/communities/ponte-vedra-real-estate',
                content: 'PONTE VEDRA',
              },
              {
                id: 'communities-item-jacksonville',
                url: 'https://www.floridacoastalteam.com/communities/jacksonville-real-estate',
                content: 'JACKSONVILLE',
              },
              {
                id: 'communities-item-jacksonville-beach',
                url: 'https://www.floridacoastalteam.com/communities/jacksonville-beach-real-estate',
                content: 'JACKSONVILLE BEACH',
              },
              {
                id: 'communities-item-atlantic',
                url: 'https://www.floridacoastalteam.com/communities/atlantic-beach-real-estate',
                content: 'ATLANTIC BEACH',
              },
              {
                id: 'communities-item-neptune',
                url: 'https://www.floridacoastalteam.com/communities/neptune-beach-real-estate',
                content: 'NEPTUNE BEACH',
              },
            ],
          },
          {
            id: 'financing-item',
            url: 'https://www.floridacoastalteam.com/financing',
            content: 'FINANCING',
          },
          {
            id: 'testimonials-item',
            url: 'https://www.floridacoastalteam.com/testimonials',
            content: 'TESTIMONIALS',
          },
          {
            id: 'team-item',
            url: 'https://www.floridacoastalteam.com/team-page',
            content: 'TEAM',
          },
          {
            id: 'contact-us-item',
            url: 'https://www.floridacoastalteam.com/new-contact-us',
            content: 'CONTACT US',
          },
          {
            id: 'blog-item',
            url: 'https://www.floridacoastalteam.com/blog',
            content: 'BLOG',
          },
          {
            id: 'home-item',
            url: 'https://www.floridacoastalteam.com/',
            content: 'HOME',
            className: 'on-grgur-desktop-special-item',
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: true,
          containerClassName: 'on-grgur-mobile-header-logo-container',
          imageClassName: 'on-grgur-mobile-header-logo',
        },
        type: 'mobile-default',
        menuOpener: {
          className: 'on-test',
          content: 'Open',
        },
        headerClassName: 'on-grgur-mobile-header',
        headerClassNameOverride: '',
        mobileMenuClassName: 'on-grgur-mobile-menu',
        menuClassName: 'on-grgur-mobile-folder',
        itemClassName: 'on-grgur-mobile-item',
        menuLabelActiveClassName: 'on-grgur-mobile-folder-active',
        menuItemClassName: 'on-grgur-mobile-item-folder',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [
          {
            id: 'buy-item',
            content: 'BUY',
            menuItems: [
              {
                id: 'buy-item-home',
                url: 'https://search.floridacoastalteam.com/',
                content: 'BUY HOME',
              },
              {
                id: 'buy-item-trade-in',
                url: 'https://trade-in.floridacoastalteam.com/',
                content: 'HOME TRADE IN',
              },
            ],
          },
          {
            id: 'sell-item',
            content: 'SELL',
            menuItems: [
              {
                id: 'sell-item-home-val',
                url: 'https://www.floridacoastalteam.com/home-valuation',
                content: 'HOME VALUATION',
              },
              {
                id: 'sell-item-why-with-us',
                url: 'https://www.floridacoastalteam.com/why-list-with-us',
                content: 'WHY LIST WITH US',
              },
              {
                id: 'sell-item-cash-offer',
                url: 'https://cashoffer.floridacoastalteam.com/',
                content: 'CASH OFFER',
              },
            ],
          },
          {
            id: 'communities-item',
            content: 'COMMUNITIES',
            menuItems: [
              {
                id: 'communities-item-all',
                url: 'https://www.floridacoastalteam.com/communities',
                content: 'ALL COMMUNITIES',
              },
              {
                id: 'communities-item-augustine',
                url: 'https://www.floridacoastalteam.com/communities/st--augustine-real-estate',
                content: 'ST. AUGUSTINE',
              },
              {
                id: 'communities-item-johns',
                url: 'https://www.floridacoastalteam.com/communities/st-johns-real-estate',
                content: 'ST JOHNS',
              },
              {
                id: 'communities-item-vedra-beach',
                url: 'https://www.floridacoastalteam.com/communities/ponte-vedra-beach-real-estate',
                content: 'PONTE VEDRA BEACH',
              },
              {
                id: 'communities-item-vedra',
                url: 'https://www.floridacoastalteam.com/communities/ponte-vedra-real-estate',
                content: 'PONTE VEDRA',
              },
              {
                id: 'communities-item-jacksonville',
                url: 'https://www.floridacoastalteam.com/communities/jacksonville-real-estate',
                content: 'JACKSONVILLE',
              },
              {
                id: 'communities-item-jacksonville-beach',
                url: 'https://www.floridacoastalteam.com/communities/jacksonville-beach-real-estate',
                content: 'JACKSONVILLE BEACH',
              },
              {
                id: 'communities-item-atlantic',
                url: 'https://www.floridacoastalteam.com/communities/atlantic-beach-real-estate',
                content: 'ATLANTIC BEACH',
              },
              {
                id: 'communities-item-neptune',
                url: 'https://www.floridacoastalteam.com/communities/neptune-beach-real-estate',
                content: 'NEPTUNE BEACH',
              },
            ],
          },
          {
            id: 'financing-item',
            url: 'https://www.floridacoastalteam.com/financing',
            content: 'FINANCING',
          },
          {
            id: 'testimonials-item',
            url: 'https://www.floridacoastalteam.com/testimonials',
            content: 'TESTIMONIALS',
          },
          {
            id: 'team-item',
            url: 'https://www.floridacoastalteam.com/team-page',
            content: 'TEAM',
          },
          {
            id: 'contact-us-item',
            url: 'https://www.floridacoastalteam.com/new-contact-us',
            content: 'CONTACT US',
          },
          {
            id: 'blog-item',
            url: 'https://www.floridacoastalteam.com/blog',
            content: 'BLOG',
          },
          {
            id: 'home-item',
            url: 'https://www.floridacoastalteam.com/',
            content: 'HOME',
            className: 'on-grgur-mobile-special-item',
          },
        ],
      },
    },
  ],
  footers: [
    {
      id: 0,
      display: true,
      className: 'on-grgur-footer-section-first d-block d-xl-flex between-xl',
      columns: [
        {
          id: 0,
          className: 'col-xl-4 align-center pt-0 d-sm-none d-md-none d-lg-none d-xl-block',
          style: {},
          items: [
            {
              id: 0,
              type: 'image',
              className: 'on-grgur-footer-img',
              alt: 'img',
              url: 'https://si-homelight.s3.amazonaws.com/sites/grgur/logo-footer.png',
              style: { width: '230px' },
            },
            {
              id: 1,
              type: 'heading',
              className: 'on-grgur-footer-copyright-text pt-2',
              style: { fontWeight: 'bold' },
              data: '©Florida Coastal Team - eXp',
            },
            {
              id: 2,
              type: 'heading',
              className: 'on-grgur-footer-copyright-text pt-0',
              style: { fontWeight: 'bold' },
              data: 'Realty 2022',
            },
            {
              id: 3,
              type: 'heading',
              className: 'on-grgur-footer-copyright-text',
              style: {},
              data: 'All Rights Reserved',
            },
          ],
        },
        {
          id: 1,
          className: 'col-xl-4 pt-xl-1',
          style: {},
          items: [
            {
              id: 0,
              type: 'heading',
              elementType: 'h1',
              className: 'on-grgur-footer-heading',
              style: {},
              data: 'Popular Pages',
            },
            {
              id: 1,
              type: 'menu',
              className: '',
              itemClassName: 'mb-0 on-grgur-footer-menu-popular',
              style: { display: 'grid' },
              items: [
                {
                  id: 0,
                  data: 'Buy',
                  url: 'https://search.floridacoastalteam.com/',
                },
                {
                  id: 1,
                  data: 'Sell',
                  url: 'https://www.floridacoastalteam.com/home-valuation',
                },
                {
                  id: 2,
                  data: 'Communities',
                  url: 'https://www.floridacoastalteam.com/communities',
                },
                {
                  id: 3,
                  data: 'Financing',
                  url: 'https://www.floridacoastalteam.com/financing',
                },
                {
                  id: 4,
                  data: 'Testimonials',
                  url: 'https://www.floridacoastalteam.com/testimonials',
                },
                {
                  id: 5,
                  data: 'Team',
                  url: 'https://www.floridacoastalteam.com/team-page',
                },
                {
                  id: 6,
                  data: 'Contact Us',
                  url: 'https://www.floridacoastalteam.com/new-contact-us',
                },
                {
                  id: 7,
                  data: 'Blog',
                  url: 'https://www.floridacoastalteam.com/blog',
                },
                {
                  id: 8,
                  data: 'Home',
                  url: 'https://www.floridacoastalteam.com/',
                },
              ],
            },
            {
              id: 2,
              type: 'heading',
              elementType: 'h1',
              className: 'on-grgur-footer-heading pt-3 mt-3',
              style: {},
              data: 'Office Hours',
            },
            {
              id: 3,
              type: 'menu',
              className: 'on-grgur-footer-menu',
              itemClassName: 'on-grgur-footer-menu-items mb-0',
              style: {},
              items: [
                {
                  id: 0,
                  data: 'Monday - 8:00am - 8:00pm',
                },
                {
                  id: 1,
                  data: 'Tuesday - 8:00am - 8:00pm',
                },
                {
                  id: 2,
                  data: 'Wednesday - 8:00am - 8:00pm',
                },
                {
                  id: 3,
                  data: 'Thursday - 8:00am - 8:00pm',
                },
                {
                  id: 4,
                  data: 'Friday - 8:00am - 8:00pm',
                },
                {
                  id: 5,
                  data: 'Saturday - 8:00am - 8:00pm',
                },
                {
                  id: 6,
                  data: 'Sunday - 10:00am - 8:00pm',
                },
              ],
            },
          ],
        },
        {
          id: 2,
          className: 'col-xl-4 pt-3 pt-xl-1 on-grgur-footer-info',
          style: {},
          items: [
            {
              id: 0,
              type: 'menu',
              className: 'pt-3 pt-xl-1',
              itemClassName: 'mb-0',
              style: {},
              items: [
                {
                  id: 0,
                  data: 'Florida Coastal Team - EXp Realty',
                },
                {
                  id: 1,
                  data: '101 East Town Place #130',
                },
                {
                  id: 2,
                  data: 'St. Augustine, FL, 32092',
                },
              ],
            },
            {
              id: 1,
              type: 'menu',
              className: 'd-flex mb-0',
              style: {},
              items: [
                {
                  id: 0,
                  className: 'd-flex mb-0',
                  data: 'Phone:',
                },
                {
                  id: 1,
                  style: { height: 'fit-content' },
                  className: 'd-flex ps-2 mb-0',
                  data: '(904) 664-9864',
                  url: 'tel:+9046649864',
                },
              ],
            },
            {
              id: 1,
              type: 'menu',
              className: 'd-flex mb-0',
              style: {},
              items: [
                {
                  id: 0,
                  className: 'd-flex',
                  data: 'Email:',
                },
                {
                  id: 1,
                  className: 'd-flex ps-2',
                  data: 'Info@Floridacoastalteam.Com',
                  url: 'mailto:Info@Floridacoastalteam.Com',
                },
              ],
            },
          ],
        },
        {
          id: 0,
          className: 'col-xl-4 align-center pt-3 mt-3 d-xl-none',
          style: {},
          items: [
            {
              id: 0,
              type: 'image',
              className: 'on-grgur-footer-img',
              alt: 'img',
              url: 'https://si-homelight.s3.amazonaws.com/sites/grgur/logo-footer.png',
              style: { width: '230px' },
            },
            {
              id: 1,
              type: 'heading',
              className: 'on-grgur-footer-copyright-text pt-2',
              style: { fontWeight: 'bold' },
              data: '©Florida Coastal Team - eXp',
            },
            {
              id: 2,
              type: 'heading',
              className: 'on-grgur-footer-copyright-text pt-0',
              style: { fontWeight: 'bold' },
              data: 'Realty 2022',
            },
            {
              id: 3,
              type: 'heading',
              className: 'on-grgur-footer-copyright-text',
              style: {},
              data: 'All Rights Reserved',
            },
          ],
        },
      ],
    },
    {
      id: 2,
      display: true,
      className: 'on-grgur-footer-section-last',
      columns: [
        {
          id: 1,
          className: 'align-center',
          style: {},
          items: [
            {
              id: 0,
              className: 'on-grgur-footer-hline',
              type: 'heading',
              style: {},
              elementType: 'hr',
            },
            {
              id: 1,
              type: 'heading',
              elementType: 'a',
              url: 'https://www.floridacoastalteam.com/#',
              className: 'on-grgur-footer-privacy-heading pt-5 pb-4',
              style: {},
              data: 'PRIVACY POLICY',
            },
            {
              id: 2,
              type: 'heading',
              className: 'on-grgur-footer-text pt-3',
              elementType: 'p',
              style: {},
              data: 'The data relating to real estate for sale on this web site comes in part from the Internet Data Exchange (IDX) program of the Northeast Florida Multiple Listing Service, Inc. Real estate listings held by brokerage firms other than are marked with the listing broker’s name. IDX information is provided exclusively for consumers’ personal, non-commercial use that it may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing, and that data is deemed reliable but is not guaranteed accurate by NEFMLS. The broker providing this data believes them to be correct, but advises interested parties to confirm them before relying on them in a purchase or lease decision. Data up to date as of Fri Apr 15 2022 17:08:41 GMT+0200 (Central European Summer Time). Some properties that appear for sale on this web site may subsequently have been sold and may no longer be available. For the most current information, contact eXp Realty',
            },
            {
              id: 3,
              type: 'menu',
              className: 'd-flex align-center pb-3',
              style: {},
              items: [
                {
                  id: 0,
                  className: '',
                  data: '(904) 664-9864',
                  url: 'tel:+9046649864',
                },
                {
                  id: 1,
                  className: 'ps-2',
                  data: 'Info@Floridacoastalteam.Com',
                  url: 'mailto:Info@Floridacoastalteam.Com',
                },
              ],
            },
            {
              id: 4,
              type: 'heading',
              className: 'on-grgur-footer-text',
              elementType: 'p',
              style: {},
              data: 'IDX information is provided exclusively for consumers’ personal, non-commercial use, that it may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing, and that the data is deemed reliable but is not guaranteed accurate by the MLS. The MLS may, at its discretion, require use of other disclaimers as necessary to protect participants and/or the MLS from liability. Information is deemed reliable but not guaranteed. Listing information courtesy of Midlands MLS.',
            },
            {
              id: 5,
              type: 'heading',
              className: 'on-grgur-footer-text pt-3',
              elementType: 'p',
              style: {},
              data: 'Copyright 2022 St. Augustine MLS. All rights reserved.',
            },
          ],
        },
      ],
    },
  ],
  substitute: {},
  replace: {},
  siteData: {
    agency: 'Florida Coastal Team',
    agentDescription: 'Top agents in Florida',
    agentAvatar: 'https://si-homelight.s3.amazonaws.com/sites/grgur/agent.jpg',
    heroBackground: 'https://si-homelight.s3.amazonaws.com/sites/grgur/hero-bg.jpg',
    expressFee: '1.5%',
  },
};
